// 取token
export function getToken() {
  return sessionStorage.getItem("token");
}
// 存token
export function setToken(token) {
  return sessionStorage.setItem("token", token);
}

// 取userId
export function getUserId() {
  return sessionStorage.getItem("userId");
}
// 存userId
export function setUserId(userId) {
  return sessionStorage.setItem("userId", userId);
}

// 取客户经理等数据
export function getDate() {
  return sessionStorage.getItem("date");
}
// 存客户经理等数据
export function setDate(date) {
  return sessionStorage.setItem("date", date);
}
