import Vue from "vue";
import App from "./App.vue";
// 引入路由
import router from "./router/index";
//引入 Vant
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
// 引入 ElementUI库
// import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
// Vue.use(ElementUI);
// 引入重置样式
import "./utils/reset.css";
// 引入微信jssdk
import { wx } from "weixin-js-sdk";
Vue.prototype.$wx = wx;

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
