// 引入
import Vue from "vue";
import Router from "vue-router";

import { getToken } from "../utils/LocalStorage";

// 注册
Vue.use(Router);

// 路由结构
const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  // 首页
  {
    path: "/home",
    component: () => import("@/views/Home/Home.vue"),
  },
  // 进件
  {
    path: "/incomingParts",
    component: () => import("@/views/IncomingParts/IncomingParts.vue"),
  },
  // 提交成功
  {
    path: "/submittedSuccessfully",
    component: () =>
      import("@/views/SubmittedSuccessfully/SubmittedSuccessfully.vue"),
  },
  // 订单记录
  {
    path: "/orderRecord",
    component: () => import("@/views/OrderRecord/OrderRecord.vue"),
  },
  // 订单详情
  {
    path: "/orderDetails",
    component: () => import("@/views/OrderDetails/OrderDetails.vue"),
  },
  // 订单进度
  {
    path: "/orderProgress",
    component: () => import("@/views/OrderProgress/OrderProgress.vue"),
  },
  // 登录页
  {
    path: "/login",
    component: () => import("@/views/Login/Login.vue"),
  },
  // 登录页1
  {
    path: "/login1",
    component: () => import("@/views/Login1/Login1.vue"),
  },
  // 忘记密码
  {
    path: "/forgetPassword",
    component: () => import("@/views/ForgetPassword/ForgetPassword.vue"),
  },
  // 404页
  {
    path: "/404",
    component: () => import("../views/404/404.vue"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];
// 实例化
const router = new Router({
  routes,
});
// 全局路由守卫;
router.beforeEach((to, from, next) => {
  // 判断是否存在token
  if (getToken()) {
    next();
  } else {
    if (
      to.path == "/login" ||
      to.path == "/forgetPassword" ||
      to.path == "/login1"
    ) {
      next();
    } else {
      next({ path: "/login" });
    }
  }
});
// 主动给滚动到顶部
router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
